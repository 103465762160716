import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '../components/pageMeta';
import { Footer } from '../components/nav/footer';

import BlockZone from '../components/blockZone/zone.development';

const PageTemplate = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data && data.page;

  const { title, meta, pageBlocks, pageTheme } = page || {};

  useEffect(() => {
    if (pageTheme) {
      const themeArray = Object.entries(pageTheme);
      themeArray.forEach(([key, value]) => {
        document.documentElement.style.setProperty(`--${key}`, `${value.hex}`);
      });
    }
  }, [pageTheme]);
  return (
    <>
      <PageMeta {...meta} />
      {pageBlocks && <BlockZone {...pageBlocks} pageTheme={pageTheme} />}
      <Footer />
    </>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
      pageTheme {
        ...Theme
      }
    }
  }
`;
